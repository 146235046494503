<template>
  <div>
    <SimpleModal ref="hotspotitem" boxClass="infoBox" :onHide="hotspotitem_onHide">
    </SimpleModal>
    <SimpleModal ref="mapModal" boxClass="mapModal">
      <div v-if="$refs.mapModal&&$refs.mapModal.show" style="width:90vw;height:90vh;position:relative">
        <PanoMap ref="PanoMap" zoom="max" :currentItem="pano" :currentLoc="{lng:pano.gpsLng,lat:pano.gpsLat}" :mapCenter="{lng:pano.gpsLng,lat:pano.gpsLat}">
          <template v-slot:buttons>
            <button @click="mapBackToPano" style="line-height: 2rem;height: 2.5rem;">回到全景位置</button>
          </template>
        </PanoMap>
        <i style="position:absolute;top:3px;right:3px;padding:5px;z-index: 1000;" class="fas fa-times text-shadow" @click="$refs.mapModal.show=false" title="关闭" />
      </div>
    </SimpleModal>
    <SimpleModal ref="screenshot">
      <div v-show="$refs.screenshot&&$refs.screenshot.show" class="" style="position:relative">
        <div id="screenshotBox" style="position:relative;overflow:hidden;">
          <img :src="screenshotUrl" style="max-height:80vh" />
        </div>
        <div class="text-center">
          <strong>
            长按图片保存或分享给朋友
          </strong>          <!--<button>重置位置</button>-->
        </div>
        <div class="text-shadow" style="position:absolute;top:0;right:4px;cursor:pointer;" @click="$refs.screenshot.show=false">
          <i class="fa fa-times"></i>
        </div>
      </div>
    </SimpleModal>
    <SimpleModal ref="screenshoterr">
      <div class="" style="max-width:280px">
        <div id="screenshotBox" style="position:relative;overflow:hidden;">
          <img :src="screenshotUrl" style="width:100%" />
        </div>
        <div class="">
          <strong>
            <a href="https://panorover.com/B/tQ6" target="_blank">
              说明：看到本信息说明您的安卓微信不支持本功能，请点击右上角“…”“在浏览器打开”后生成二维码分享海报。
            </a>
          </strong>
        </div>
        <div class="text-shadow" style="position:absolute;top:0;right:4px;cursor:pointer;" @click="$refs.screenshoterr.show=false">
          <i class="fa fa-times"></i>
        </div>
      </div>
    </SimpleModal>
    <SimpleModal ref="panoinfo" boxClass="infoBox" :boxStyle="{'background-color':'unset'}">
      <!--{{pano}}-->
      <div class="panoinfo">
        <div style="position:relative">
          <h6 style="margin-right: 20px;">
            {{pano.title}}
          </h6>
          <div class="" style="position:absolute;top:-5px;right:0;padding:5px;cursor:pointer;" @click="$refs.panoinfo.show=false">
            <i class="fa fa-times"></i>
          </div>
          <div v-if="pano.text" style="max-height:30vh;overflow:auto">
            <article v-html="pano.text"></article>
          </div>
        </div>
        <div v-if="pano.exif&&!pano.isPro">
          <hr />
          <table class="exif">
            <tr v-if="pano.addTime">
              <th>发布时间：</th>
              <td>{{pano.addTime}}</td>
            </tr>
            <tr v-if="pano.exif.dateTimeOriginal">
              <th>拍摄时间：</th>
              <td>{{pano.exif.dateTimeOriginal}}</td>
            </tr>
            <tr v-if="pano.exif.make">
              <th>相机品牌：</th>
              <td>{{pano.exif.make}}</td>
            </tr>
            <tr v-if="pano.exif.model">
              <th>相机型号：</th>
              <td>{{pano.exif.model}}</td>
            </tr>
            <tr v-if="pano.exif.exposureTime">
              <th>曝光时间：</th>
              <td>{{pano.exif.exposureTime}}</td>
            </tr>
            <tr v-if="pano.exif.fNumber">
              <th>光圈值：</th>
              <td>{{pano.exif.fNumber}}</td>
            </tr>
            <tr v-if="pano.exif.isoSpeedRatings">
              <th>ISO 值：</th>
              <td>{{pano.exif.isoSpeedRatings}}</td>
            </tr>
            <tr v-if="pano.exif.focalLength">
              <th>焦距：</th>
              <td>{{pano.exif.focalLength}}</td>
            </tr>
            <tr v-if="pano.exif.lensModel">
              <th>镜头：</th>
              <td>{{pano.exif.lensModel}}</td>
            </tr>
            <tr v-if="pano.exif.software">
              <th>软件：</th>
              <td>{{pano.exif.software}}</td>
            </tr>
          </table>
        </div>
      </div>
    </SimpleModal>
  </div>
</template>
<script>
  import SimpleModal from '../../SimpleModal'
  export default {
    components: {
      SimpleModal
    },
    props: {
      pano: {
        default: {}
      },
    },
    data() {
      return {
      }
    },
    inject: {
      pano: {
        default: {}
      }
    },
    computed: {
    },
    created() {
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        events: {
        },
        funcs: {
          hotspotitem: () => { this.$refs.hotspotitem.show = true },
          mapModal: () => { this.$refs.mapModal.show = true },
          showScreenshot: () => { this.$refs.screenshot.show = true },
          screenshoterr: () => { this.$refs.screenshoterr.show = true },
        }
      })
    },
    destroyed() {
    },
    methods: {
    },
  }
</script>
<style scoped>
  .panoinfo {
    width: 70vw;
    max-width: 600px;
    min-width: 240px;
    color: #fff;
  }

  .exif {
    font-size: 1.2rem
  }

    .exif tr:first-child {
      white-space: nowrap
    }

    .exif td {
      padding: 0 0 0 0;
      border-bottom: none;
    }

    .exif th {
      padding: 0 0 0 0;
      text-align: right;
      width: 6em;
      vertical-align: top;
      border-bottom: none;
    }
</style>
